import { useState } from "react"

export function Speaker({ onClick, isTouch }) {
    
    const [volumeOn, setVolumeOn] = useState(true)
    
    return <div className="hud__speaker" onClick={ onClick }>
        
        <div className="mobile-speaker"> 
            <svg className={ volumeOn ? 'volume-on' : '' } xmlns="http://www.w3.org/2000/svg" width="18.819" height="33.162" viewBox="0 0 18.819 33.162" onClick={ () => setVolumeOn(!volumeOn) }>
                <g id="Grupo_7835" data-name="Grupo 7835" transform="translate(-43.723 -763.394)">
                <path id="Trazado_37927" data-name="Trazado 37927" d="M31.84,35.557l-.621-.784a20.15,20.15,0,0,0,0-31.595l.621-.784a21.137,21.137,0,0,1,0,33.162Z" transform="translate(22.68 761)" fill="#9bcaa6"/>
                <path id="Trazado_37928" data-name="Trazado 37928" d="M28.009,27.956l-.607-.8a12.451,12.451,0,0,0,0-19.8l.607-.795A13.5,13.5,0,0,1,30.545,9.1a13.452,13.452,0,0,1-2.536,18.853Z" transform="translate(21.11 762.716)" fill="#9bcaa6"/>
                <path id="Trazado_37930" data-name="Trazado 37930" d="M24.574,21.145l-.559-.829a5.545,5.545,0,0,0,.01-9.186l.561-.828a6.544,6.544,0,0,1-.012,10.843Z" transform="translate(19.709 764.255)" fill="#9bcaa6"/>
                </g>
            </svg>
        </div>
      
       {/*  { !isTouch && 
        <svg xmlns="http://www.w3.org/2000/svg" width="35.841" height="28.677" viewBox="0 0 35.841 28.677" onClick={ () => setVolumeOn(!volumeOn) }>
            <g transform="translate(0.5 0.858)">
                <rect width="7.601" height="14.961" transform="translate(0 6)" fill="none" stroke="#141515" strokeWidth="1"/>
                <path d="M18.7,27.819l-10.6-6V6.858l10.6-6Z" transform="translate(-0.5 -0.857)" fill="none" stroke="#141515" strokeWidth="1"/>
                {volumeOn === true && <>
                    <path className="bar3" d="M29.789,25.816a14.64,14.64,0,0,0,0-22.955" transform="translate(-0.5 -0.857)" fill="none" stroke="#141515" strokeWidth="1"/>
                    <path className="bar2" d="M26.269,21.64a9.182,9.182,0,0,0,0-14.6" transform="translate(-0.5 -0.857)" fill="none" stroke="#141515" strokeWidth="1"/>
                    <path className="bar1" d="M23.1,17.891a4.285,4.285,0,0,0,.008-7.1" transform="translate(-0.5 -0.857)" fill="none" stroke="#141515" strokeWidth="1"/>
                </>}
            </g>
        </svg>} */}
    </div>
}