import { Html, Mask, Merged, Svg, Text, useCursor, useGLTF, useMask } from "@react-three/drei"
import { useFrame, useLoader } from "@react-three/fiber"
import { Power1, gsap } from "gsap/all"
import React, { useEffect, useMemo, useRef, useState } from "react"
import * as THREE from 'three'
import { ModelCushion } from "./Models/ModelCushion"
import { ModelSmartphone } from "./Models/ModelSmartphone"
import { damp, damp3 } from "maath/easing"
import { Frame } from "../Frame"
import { useControls } from "leva"
import WindowHandler from "../Utils/WindowHandler"


function FirstFloor( { args, materialFloorShowed, matrixAutoUpdate } ) {
    const { longitud_1, anchura_1, pos_x_1, pos_y_1, pos_z_1 } = args

    const planeGeometry = useMemo(() => new THREE.PlaneGeometry( 1, 1 ), [])

    return <>

        <mesh
            matrixAutoUpdate={ matrixAutoUpdate }
            position={ [ pos_x_1, pos_y_1, longitud_1 / 2 + pos_z_1 - 200 ] }
            material={ materialFloorShowed }
            geometry={ planeGeometry }
            scale={ [ anchura_1, longitud_1 + 400, 1 ] }
            rotation={ [ -Math.PI * .5, 0, 0 ] }
        />
        

    </>
}

export function ThirdFloor( { args, material, cushionMaterial, smartphoneMaterial, colorPositive, colorFloorPositive, cursor, expStep, expStep_PREV, invisibleMaterial, matrixAutoUpdate } ) {
    const { longitud_1, anchura_1, longitud_2, longitud_3, pos_x_1, pos_y_1, pos_z_1 } = args

    const cylLarge = useRef()
    const cylMedium = useRef()
    const cylSmall = useRef()
    const cushoinGroup = useRef()
    const graphsGroup = useRef()
    const floor1 = useRef()
    const floor2 = useRef()

    const phoneWrapper = useRef()
    const isTouch = WindowHandler().isTouch

    const planeGeometry = useMemo(() => new THREE.PlaneGeometry( 1, 1 ), [])

    useEffect(() => {
        if (expStep === 9) {
            gsap.to(cylLarge.current.scale, {
                y: .01,
                duration: 4, ease: Power1.easeInOut
            })
            gsap.to(cylLarge.current.position, {
                y: -.01,
                duration: 4, ease: Power1.easeInOut
            })
            gsap.to(cushoinGroup.current.position, {
                y: -.01,
                duration: 4, ease: Power1.easeInOut
            })

            gsap.to(cylMedium.current.scale, {
                y: .01,
                duration: 4, ease: Power1.easeInOut
            })
            gsap.to(cylMedium.current.position, {
                y: -.02,
                duration: 4, ease: Power1.easeInOut
            })

            gsap.to(cylSmall.current.scale, {
                y: .01,
                duration: 4, ease: Power1.easeInOut
            })
            gsap.to(cylSmall.current.position, {
                y: -.01,
                duration: 4, ease: Power1.easeInOut
            })
            
            gsap.to(floor2.current.material, {
                opacity: 0,
                duration: 4, ease: Power1.easeInOut
            })
        }
        
        if (expStep === 10) {
            if (window.innerWidth <= 600) {
                gsap.to(graphsGroup.current.position, {
                    x: pos_x_1 - window.innerWidth <= 600 ? -.6 : 13.4,
                    duration: 4, ease: Power1.easeInOut
                })
            }
            gsap.to(cylLarge.current.scale, {
                y: 4,
                duration: 4, ease: Power1.easeInOut
            })
            gsap.to(cylLarge.current.position, {
                y: 4,
                duration: 4, ease: Power1.easeInOut
            })
            gsap.to(cushoinGroup.current.position, {
                y: 24,
                duration: 4, ease: Power1.easeInOut
            })

            gsap.to(cylMedium.current.scale, {
                y: 4,
                duration: 4, ease: Power1.easeInOut
            })
            gsap.to(cylMedium.current.position, {
                y: 4,
                duration: 4, ease: Power1.easeInOut
            })

            gsap.to(cylSmall.current.scale, {
                y: 4,
                duration: 4, ease: Power1.easeInOut
            })
            gsap.to(cylSmall.current.position, {
                y: 4,
                duration: 4, ease: Power1.easeInOut
            })

            gsap.to(floor2.current.material, {
                opacity: 1,
                duration: 4, ease: Power1.easeInOut
            })
            gsap.to(graphsGroup.current.postion, {
                x: -6,
                duration: 4, ease: Power1.easeInOut
            })
        }
        if (expStep === 11) {
            if (window.innerWidth <= 600) {
                gsap.to(graphsGroup.current.position, {
                    x: pos_x_1 - window.innerWidth <= 600 ? -13 : 13.4,
                    duration: 4, ease: Power1.easeInOut
                })
            }
        }
    }, [expStep])

    const OffsetZ = 20;

    const texture = useLoader(THREE.TextureLoader, '/textures/mobile_layout.png')
    
    texture.encoding = THREE.sRGBEncoding;
    const mobileScreenMaterial = useMemo(() => new THREE.MeshBasicMaterial({ map: texture, transparent: true }), [])

    const { nodes } = useGLTF("/models/UV/Graphs.glb");

    const graph1Tex = useLoader(THREE.TextureLoader, 'textures/graphs/graph1.jpg')
    const graph2Tex = useLoader(THREE.TextureLoader, 'textures/graphs/graph2.jpg')
    const graph3Tex = useLoader(THREE.TextureLoader, 'textures/graphs/graph3.jpg')
    const floor1Tex = useLoader(THREE.TextureLoader, 'textures/graphs/floor1.jpg')
    const floor3Tex = useLoader(THREE.TextureLoader, 'textures/graphs/floor3.jpg')
    graph1Tex.flipY = false; graph1Tex.encoding = THREE.sRGBEncoding
    graph2Tex.flipY = false; graph2Tex.encoding = THREE.sRGBEncoding
    graph3Tex.flipY = false; graph3Tex.encoding = THREE.sRGBEncoding
    floor1Tex.flipY = false; floor1Tex.encoding = THREE.sRGBEncoding
    floor3Tex.flipY = false; floor3Tex.encoding = THREE.sRGBEncoding


    const stencilShow = useMask(1, true)
    const colorConfig = useControls('Color', {
        triangle: '#81BC8F'
    })
    const wallMaterial = useMemo(() => new THREE.MeshBasicMaterial({ color: '#9bcaa6' }), [])
    const triangleMaterial = useMemo(() => new THREE.MeshBasicMaterial({ color: colorConfig.triangle, ...stencilShow }), [])

    const triangleGeometry = useMemo(() => new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(0, .5, 0),new THREE.Vector3(-.6, -.5, 0),new THREE.Vector3(.6, -.5, 0)]), [])

    const triangle = useRef()
    const square = useRef()
    const rhombus = useRef()
    const circle = useRef()
    const hoverFollower = useRef()
    const currentHover = useRef(-1)
    const sectionClicked = useRef(false) 
    const over = (link) => {
        currentHover.current = link
    }

    const positions = [
        {x: -5.3, y: 4.3},
        {x: 5, y: 4},
        {x: -5.3, y: 1},
        {x: 5.5, y: 1},
    ]

    useEffect(() => {
        if (expStep === 9 && expStep_PREV === 8) {
            cylLarge.current.rotation.y = 0
        }
      }, [expStep])

    useFrame((state, delta) => {
        if (isTouch) return
        if (!(expStep === 10 || expStep === 11)) return

        const scale = (currentHover.current >= 0 && !sectionClicked.current) ? 4 : 0
        
        damp3(hoverFollower.current.scale, [scale, scale, scale], 0.25, delta)
        if (currentHover.current !== -1) {
            const pos = positions[currentHover.current]

            damp3(hoverFollower.current.position, [
                pos.x + cursor.x * 2, 
                pos.y + -cursor.y * 2, 
                0
            ], 0.25, delta)

            const triangleScale = (currentHover.current === 0) ? 1 : 0
            damp3(triangle.current.scale, [triangleScale, triangleScale, triangleScale], 0.25, delta)

            const squareScale = (currentHover.current === 1) ? 1 : 0
            damp3(square.current.scale, [squareScale, squareScale, squareScale], 0.25, delta)

            const rhombusScale = (currentHover.current === 2) ? 1 : 0
            damp3(rhombus.current.scale, [rhombusScale, rhombusScale, rhombusScale], 0.25, delta)

            const circleScale = (currentHover.current === 3) ? 1 : 0
            damp3(circle.current.scale, [circleScale, circleScale, circleScale], 0.25, delta)
        }



        
        phoneWrapper.current.rotation.set(
            THREE.MathUtils.lerp(phoneWrapper.current.rotation.x, cursor.y * .1 + .05, .1),
            THREE.MathUtils.lerp(phoneWrapper.current.rotation.y, cursor.x * .4, .1),
            phoneWrapper.current.rotation.z,
        )

        cylLarge.current.rotation.y += delta * .3 
    })

    const clickedSection = (section) => {
        sectionClicked.current = true
        if (section === 0) window.location.href = '/producto'
        if (section === 1) window.location.href = '/producto'
        if (section === 2) window.location.href = '/inversion'
        if (section === 3) window.location.href = '/inversion'
    }



    return <>
    
        <mesh
            position={ [ pos_x_1, - longitud_2 + pos_y_1, longitud_1 + longitud_3 / 2 + pos_z_1 ] }
            geometry={ planeGeometry }
            material={ material }
            scale={ [ anchura_1, longitud_3 * 2, 1 ] }
            rotation={ [ -Math.PI * .5, 0, 0 ] }
            matrixAutoUpdate={ matrixAutoUpdate }
        />

        <group 
            position={ [ window.innerWidth <= 600 ? (pos_x_1 - window.innerWidth <= 600 ? -.6 : 13.4) : (pos_x_1 - (window.innerWidth <= 600 ? -1 : 13.4)), - longitud_2 + pos_y_1, longitud_1 - OffsetZ + 3.5 ] }
            rotation={ [ 0, -Math.PI * .5, 0 ] }
            scale={ 1 }
            ref={ graphsGroup }
        >
            <group ref={ cushoinGroup }>
                <ModelCushion materials={ cushionMaterial } position={ [ 0, 1.6, -13.6 ] } scale={ 1.2 } expStep={ expStep } expStep_PREV={ expStep_PREV } />
            </group>
            <mesh
                ref={ cylLarge }
                geometry={nodes.Cylinder001.geometry}
                position={[0, -.01, -13.51]}
                scale={ [ 4.5, .01, 4.5 ] }
            >
                <meshMatcapMaterial map={ graph1Tex } color={ colorPositive } />
            </mesh>
            <mesh
                ref={ cylMedium }
                geometry={nodes.Cylinder.geometry}
                position={[0, -.01, 0]}
                scale={ [ 4.5, 0, 4.5 ] }
            >
                <meshMatcapMaterial map={ graph2Tex } color={ colorPositive } />
            </mesh>
            <mesh
                ref={ cylSmall }
                geometry={nodes.Cylinder002.geometry}
                position={[0, -.01, 13.51]}
                scale={ [ 4.5, 0, 4.5 ] }
            >
                <meshMatcapMaterial map={ graph3Tex } color={ colorPositive } />
            </mesh>


            <mesh
                ref={ floor1 }
                matrixAutoUpdate={ matrixAutoUpdate }
                geometry={nodes.Plane.geometry}
                position={[0, 0.02, 0]}
                scale={[15.4, 15.4, 31.16]}
            >
                <meshMatcapMaterial map={ floor1Tex } color={ colorFloorPositive } />
            </mesh>
            <mesh
                ref={ floor2 }
                matrixAutoUpdate={ matrixAutoUpdate }
                geometry={nodes.Plane.geometry}
                position={[0, 0.03, 0]}
                scale={[15.4, 15.4, 31.16]}
            >
                <meshMatcapMaterial map={ floor3Tex } color={ colorFloorPositive } opacity={ 0 } transparent={ true } />
            </mesh>

        </group>


        {/* MOBILE */}
        <group
            position={ [ pos_x_1, - longitud_2 + pos_y_1 + 17.3, longitud_1 - OffsetZ + 155 ] }
            rotation={ [ -Math.PI * .005, 0, 0 ] }
            scale={ 3 }
        >

            <group ref={ phoneWrapper } position-z={ 1 }>

                <ModelSmartphone 
                    matrixAutoUpdate={ matrixAutoUpdate }
                    materials={ smartphoneMaterial }
                    scale={ .465 }
                    position={ [ -.17, 2.7, .13 ] }
                />

                <group
                    position={ [ 0, 2.5, .19 ] }
                >

                        

                    <mesh
                    matrixAutoUpdate={ matrixAutoUpdate } 
                    material={ mobileScreenMaterial } 
                    scale={ [ 3.3, 6.68, 1 ] }>
                        <planeGeometry />
                        
                    </mesh>

                </group>

            </group>


            {/* FALSE SCREEN */}
            <mesh
                matrixAutoUpdate={ matrixAutoUpdate }
                position={ [ 26.5, 0, -.1 ] }
                material={ wallMaterial }
                geometry={ planeGeometry }
                scale={ [ 50, 50, 1 ] }
            />
            <mesh
                matrixAutoUpdate={ matrixAutoUpdate }
                position={ [ -26.5, 0, -.1 ] }
                material={ wallMaterial }
                geometry={ planeGeometry }
                scale={ [ 50, 50, 1 ] }
            />
            <mesh
                matrixAutoUpdate={ matrixAutoUpdate }
                position={ [ 0, 20, -.1 ] }
                material={ wallMaterial }
                geometry={ planeGeometry }
                scale={ [ 30, 30, 1 ] }
            />
            <mesh
                matrixAutoUpdate={ matrixAutoUpdate }
                position={ [ 0, -15, -.1 ] }
                material={ wallMaterial }
                geometry={ planeGeometry }
                scale={ [ 30, 30, 1 ] }
            />

            {!isTouch && <>
                <group ref={ hoverFollower }>
                    <mesh ref={ triangle } geometry={ triangleGeometry } material={ triangleMaterial } />
                    <mesh ref={ rhombus } geometry={ planeGeometry } material={ triangleMaterial } />
                    <mesh ref={ square } geometry={ planeGeometry } material={ triangleMaterial } rotation={ [ 0, 0, Math.PI * .25 ] } />
                    <mesh ref={ circle } material={ triangleMaterial }>
                        <circleGeometry args={[0.55, 64, 1]} />
                    </mesh>
                </group>
                <Mask id={1} position={ [ 0, 3.5, 1 ] } geometry={ planeGeometry } scale={2.5} />

                <TextBlock
                    matrixAutoUpdate={ matrixAutoUpdate } 
                    position={ [ -7, 4.5, .001 ] } 
                    number={ "01" } 
                    title={ "SOSTENIBLE Y\nRESPONSABLE" } 
                    text={ "El pilar donde se sustenta Dianelum. Un diamante ético y comprometido con el futuro medioambiental, social y moral. Sin perder ni una pizca de autenticidad." } 
                    onHover={ () => { over(0) } }
                    maxWidth={ 3.19 }
                    invisibleMaterial={ invisibleMaterial }
                    onClick={ () => clickedSection(0) }
                />
                <TextBlock 
                    matrixAutoUpdate={ matrixAutoUpdate } 
                    position={ [ 4.5, 4.5, .001 ] } 
                    number={ "02" } 
                    title={ "MISMA\nCALIDAD" } 
                    text={ "El mismo ADN que un diamante de mina. Aprobado y certificado por el Instituto Gemológico Internacional (IGI) para ser considerado un diamante 100% auténtico." } 
                    onHover={ () => { over(1) } }
                    maxWidth={ 3.3 }
                    invisibleMaterial={ invisibleMaterial }
                    onClick={ () => clickedSection(1) }
                />
                <TextBlock 
                    matrixAutoUpdate={ matrixAutoUpdate } 
                    position={ [ -7, 1, .001 ] } 
                    number={ "03" } 
                    title={ "INVERSIÓN\nGARANTIZADA" } 
                    text={ "Una inversión garantizada, respaldada por bancos de diamantes de alta calidad y con una tasa de crecimiento anual de dos dígitos." } 
                    onHover={ () => { over(2) } }
                    maxWidth={ 3.19 }
                    invisibleMaterial={ invisibleMaterial }
                    onClick={ () => clickedSection(2) }
                />
                <TextBlock 
                    matrixAutoUpdate={ matrixAutoUpdate } 
                    position={ [ 4.5, 1, .001 ] } 
                    number={ "04" } 
                    title={ "SEGURIDAD\nBLOCKCHAIN" } 
                    text={ "Invierte a través de un token propio con trazabilidad basada en tecnología Blockchain y un STO regulado por la CNMV que protege a nuestros inversores." } 
                    onHover={ () => { over(3) } }
                    maxWidth={ 3.3 }
                    invisibleMaterial={ invisibleMaterial }
                    onClick={ () => clickedSection(3) }
                />
            </>}
                
            {/* {!isTouch && <>
                <TextBlock 
                    position={ [ -7, 4.5, .001 ] } 
                    number={ "01" } 
                    title={ "SOSTENIBLE Y\nRESPONSABLE" } 
                    text={ "El pilar donde se sustenta Dianelum. Un diamante ético y comprometido con el futuro medioambiental, social y moral. Sin perder ni una pizca de autenticidad." } 
                    onHover={ () => { over(0) } }
                    maxWidth={ 3.19 }
                    invisibleMaterial={ invisibleMaterial }
                    onClick={ () => clickedSection(0) }
                />
                <TextBlock 
                    position={ [ 4.5, 4.5, .001 ] } 
                    number={ "02" } 
                    title={ "MISMA\nCALIDAD" } 
                    text={ "El mismo ADN que un diamante de mina. Aprobado y certificado por el Instituto Gemológico Internacional (IGI) para ser considerado un diamante 100% auténtico." } 
                    onHover={ () => { over(1) } }
                    maxWidth={ 3.3 }
                    invisibleMaterial={ invisibleMaterial }
                    onClick={ () => clickedSection(1) }
                />
                <TextBlock 
                    position={ [ -7, 1, .001 ] } 
                    number={ "03" } 
                    title={ "INVERSIÓN\nGARANTIZADA" } 
                    text={ "Una inversión garantizada, respaldada por los principales bancos de diamantes de alta calidad y con una tasa de crecimiento anual de dos dígitos." } 
                    onHover={ () => { over(2) } }
                    maxWidth={ 3.19 }
                    invisibleMaterial={ invisibleMaterial }
                    onClick={ () => clickedSection(2) }
                />
                <TextBlock 
                    position={ [ 4.5, 1, .001 ] } 
                    number={ "04" } 
                    title={ "SEGURIDAD\nBLOCKCHAIN" } 
                    text={ "Invierte a través de un token propio con trazabilidad basada en tecnología Blockchain y un STO regulado por la CNMV que protege a nuestros inversores." } 
                    onHover={ () => { over(3) } }
                    maxWidth={ 3.3 }
                    invisibleMaterial={ invisibleMaterial }
                    onClick={ () => clickedSection(3) }
                />
            </>} */}
            

        </group>

    </>
}

function TextBlock({ position, number, title, text, onHover, maxWidth, invisibleMaterial, onClick, matrixAutoUpdate }) {
    const planeGeometry = useMemo(() => new THREE.PlaneGeometry( 1, 1 ), [])

    const pretitleFontSize = .17
    const titleFontSize = .26
    const textFontSize = .18
    const textColor = "black"

    const arrowHead = useRef()
    const arrowTail = useRef()

    const [hovered, set] = useState()
    useCursor(hovered, /*'pointer', 'auto'*/)

    const hover = () => {
        onHover()
        gsap.to(arrowHead.current.position, {
            x: .13,
            duration: .4
        })
        gsap.to(arrowTail.current.scale, {
            x: .01,
            duration: .4
        })
        set(true)
    }
    const hoverOut = () => {
        onHover()
        gsap.to(arrowHead.current.position, {
            x: 0,
            duration: .4
        })
        gsap.to(arrowTail.current.scale, {
            x: 0,
            duration: .4
        })
        set(false)
    }

    return <group position={ position }>
        <Text position={ [ -.25, .65, 0 ] } anchorY="top" anchorX="right" color={ textColor } fontSize={ pretitleFontSize } font="/fonts/kento-regular.woff" matrixAutoUpdate={ matrixAutoUpdate } >
            { number }
        </Text>
        <Text position={ [ 0, .7, 0 ] } anchorY="top" anchorX="left" color={ textColor } fontSize={ titleFontSize } font="/fonts/kento-regular.woff" matrixAutoUpdate={ matrixAutoUpdate } >
            { title }
        </Text>
        <Text anchorY="top" anchorX="left" color={ textColor } maxWidth={ maxWidth } fontSize={ textFontSize } font="/fonts/WorkSans-Regular.ttf" matrixAutoUpdate={ matrixAutoUpdate } >
            { text }
        </Text>
        <Svg ref={ arrowHead } position={ [ 0, -1.3, 0 ] } src="/svg/text_block_arrow_head.svg" scale={ .01 } />
        <Svg ref={ arrowTail } position={ [ 0, -1.37, 0 ] } src="/svg/text_block_arrow_tail.svg" scale={ [ 0, .01, .01 ] } />
        <mesh geometry={ planeGeometry } material={ invisibleMaterial } position={ [ 1.3, -.2, .01 ] } scale={[ 4, 2.5, 1 ]} onPointerOver={ () => hover() } onPointerOut={ () => hoverOut() } onClick={ onClick } matrixAutoUpdate={ matrixAutoUpdate } />
    </group>
}

export default React.forwardRef( function Terrain(props, ref) {

    const terrain = useRef()
    const [showChildren, setShowChildren] = useState(true)
    const isTouch = WindowHandler().isTouch

    useEffect(() => {
        if (props.positivePartState) {
            gsap.to(terrain.current, {visible: false, duration: 7})
        } else {
            terrain.current.visible = true
        }
    }, [props.positivePartState])


    useEffect(() => {
       /*  if (isTouch) {
            if (props.dianelumnizer) {
                setTimeout(() => {
                    setShowChildren(false)
                }, 300);
            } else {
                setShowChildren(true)
            }
        } */
    }, [props.dianelumnizer, showChildren, isTouch])

    return <>

        <group ref={ terrain } visible={ true }>
            <FirstFloor 
                matrixAutoUpdate={ props.matrixAutoUpdate }
                args={ props.configTerrain }
                materialFloorShowed={ props.materialShowed } 
            />
            <group visible={ showChildren }>
                {props.children}
            </group>
        </group>
        
    </>
})