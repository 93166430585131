import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { Power3 } from "gsap/all";
import { Power1, gsap } from "gsap/all";
import React, { useEffect, useRef } from "react";

const eases = [
  Power3.easeInOut,
  Power1.easeInOut
]

const timeStampsForcep = [
  { }, // MINA
  { }, // CHIMENEAS
  { }, // MINERO
  { }, // MINA CIELO ABIERTO
  { }, // BALAS
  { }, // VAGONETA
  { 
    position: { x: 60, y: 33, z: 375 },
    rotation: { x: 0, y: 0, z: 0 },
    scale: { x: 2.4, y: 3, z: 2.4 },
    duration: 2,
    delay: 4,
    ease: eases[0]
  }, // PORTAL
  { 
    position: { x: .6, y: 5, z: 375 },
    rotation: { x: 0, y: 0, z: Math.PI * .25 },
    scale: { x: 2.4, y: 1.9, z: 2.4 },
    duration: 4,
    delay: 0,
    ease: eases[0]
  }, // FORCEP
  { 
    position: { x: .6, y: -33.5, z: 405 },
    rotation: { x: Math.PI * .2, y: 0, z: Math.PI * .15 },
    scale: { x: 2.4, y: 1.9, z: 2.4 },
    duration: 4,
    delay: 0,
    ease: eases[0]
  }, // LOUPE
  { 
    position: { x: window.innerWidth <= 600 ? 12.4 : .4, y: -79.8, z: 390 },
    rotation: { x: 0, y: 0, z: Math.PI * .28 },
    scale: { x: 2.4, y: 1.9, z: 2.4 },
    duration: 4,
    delay: 0,
    ease: eases[0]
  }, // GRAPHS
  { 
    position: { x: 70, y: -11.5, z: 390 },
    rotation: { x: 0, y: 0, z: 0 },
    scale: { x: 2.4, y: 4, z: 2.4 },
    duration: 3,
    delay: 0,
    ease: eases[1]
  }, // GRAPHS UP 
  { }, // END
  {  }, // MOBILE ONLY: 1
  {  }, // MOBILE ONLY: 2
  {  }, // MOBILE ONLY: 3
  {  }, // MOBILE ONLY: 4 
]

const playAnimation = (forcep, stamp, prevStamp) => {

  if (!forcep.current) return
  if (stamp === null || stamp === undefined) return
  if (!timeStampsForcep[stamp].duration) return

  let delay = timeStampsForcep[stamp].delay
  if (prevStamp === 7 && stamp === 6) delay = 0
  if (prevStamp === 10 && stamp === 9) delay = .5

  let duration = timeStampsForcep[stamp].duration

  gsap.to(forcep.current.position, {
    x: timeStampsForcep[stamp].position.x,
    z: timeStampsForcep[stamp].position.z,
    y: timeStampsForcep[stamp].position.y,
    duration: duration,
    ease: timeStampsForcep[stamp].ease,
    delay: delay
  })
  gsap.to(forcep.current.rotation, {
    x: timeStampsForcep[stamp].rotation.x,
    z: timeStampsForcep[stamp].rotation.z,
    y: timeStampsForcep[stamp].rotation.y,
    duration: duration,
    ease: timeStampsForcep[stamp].ease,
    delay: delay
  })
  gsap.to(forcep.current.scale, {
    x: timeStampsForcep[stamp].scale.x,
    z: timeStampsForcep[stamp].scale.z,
    y: timeStampsForcep[stamp].scale.y,
    duration: duration,
    ease: timeStampsForcep[stamp].ease,
    delay: delay
  })
}


export default React.forwardRef( function ModelForcep(props, ref) {
  const { nodes } = useGLTF("/models/UV/Forcep.glb");

  const forcep = useRef()

  useFrame(() => {
    forcep.current.visible = !(props.expStep === 11)
  })

  useEffect(() => {
    playAnimation(forcep, props.expStep, props.expStep_PREV)
  }, [props.expStep])

  return (
    <group {...props} dispose={null} ref={ forcep }>
      <group rotation={[Math.PI / 2, 0, 0]} position={ [ 10.5, .1, 0 ] } scale={1.2}>
        <mesh
          geometry={nodes.DIA_PINZA_ALM_INF.geometry}
          material={props.materials.baked}
          position={[-9.053, 0, 0]}
        />
      </group>
    </group>
  );
})