import { Html, Text } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { Power2 } from "gsap/all";
import { gsap } from "gsap/all";
import { useEffect, useRef } from "react"
import * as THREE from 'three'



export function TextRevealer({ text, trigger, position, rotation, scale, pretitle, align, delay, endCallback, delayCallback = 0, fade, lineHeight = 1, material }) {

    const textRef = useRef([])
    const textGroupRef = useRef([])
    const canHover = useRef(false)
    const faded = useRef(false)


    useEffect(() => {
        if (!textRef.current) return
        if (trigger) {
            for (let i = 0; i < textRef.current.length; i++) {
                
                const p = { p: 0 }
                gsap.to(p, {
                    p: 1,
                    duration: delay,
                    onComplete: () => {
                        if (trigger) {
                            textRef.current[i].visible = true
                            gsap.to(textRef.current[i].material, {
                                opacity: 1,
                            })
                            gsap.fromTo(textGroupRef.current[i].position, {
                                y: -1 - i * .5,
                                z: -1 + i * 1.5
                            }, {
                                y: 0,
                                z: 0,
                                delay: i * .07,
                                duration: .8,
                            })
                            gsap.fromTo(textGroupRef.current[i].rotation, {
                                x: Math.PI * .6
                            }, {
                                x: 0,
                                delay: i * .07,
                                duration: .8,
                            })
                            gsap.to(textRef.current[i].position, {
                                y: position[1],
                                onComplete: () => {
                                    if (textRef.current[textRef.current.length - 1]) {
                                        canHover.current = true
                                        if (endCallback) {
                                            const p = { p: 0 }
                                            gsap.to(p, {p: 1, duration: delayCallback, onComplete: () => {
                                                endCallback()
                                            }})
                                        }
                                    }
                                }
                            })
                        }
                    }
                })
            }
        } else {
            for (let i = 0; i < textRef.current.length; i++) {
                if (textRef.current[i]) {
                    gsap.to(textRef.current[i].material, {
                        opacity: 0,
                        duration: 1.5
                    })
                    gsap.to(textRef.current[i].position, {
                        y: position[1] - 1,
                        duration: 1.5,
                        onComplete: () => {
                            textRef.current[i].visible = false
                        }
                    })
                }
                canHover.current = false
            }
        }
    }, [trigger])

    

/*     useFrame(() => {
        material.opacity = THREE.MathUtils.lerp(material.opacity, (fade) ? 0 : 1, 0.035)
    }) */

    return <>
        {Array.from({ length: text.length }, (_, i) => (
            <group key={ 'group1-'+i } ref={ group => textGroupRef.current[i] = group }>
                <group key={ 'group2-'+i  } position-y={ 0 - i * lineHeight }>
                    <Text
                        key={ i }
                        ref={ el => textRef.current[i] = el } 
                        position={ position } rotation={ rotation } scale={ (scale) ? scale : 1 }
                        textAlign={ (align) ? align : 'center' }
                        anchorX={ (align) ? align : 'center' }
                        font={
                            (pretitle) ?
                            "/fonts/joules.otf" :
                            "/fonts/kento-regular.woff"
                        }
                        lineHeight={ 1 }
                        material={ material }
                    >
                        { text[i] }
                    </Text>
                </group>
            </group>
        ))}
    </>
}